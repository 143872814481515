import React, { useState, useEffect } from 'react';

import SelectableMatrix from '../components/selectableMatrix';
import Layout from "../components/layout"
import { SEO } from '../components/seo';

const MatrixPage = () => {
  const [conversionResult, setConversionResult] = useState('');
  const [isHelpVisible, setIsHelpVisible] = useState(false);

  const format2DArray = (target) => {
    let result = "uint8_t frame[8][12] = {\n";
    for (let i = 0; i < target.length; i++) {
        result += "    {" + target[i].join(",") + "},\n";
    }
    result += "};";
    return result;
  };


  const bits2long = (target) => {
    const frame = [0, 0, 0];

    let shift_pos = 31;
    let array_pos = 0;

    for (let i = 0; i < 8; i++) {
        for (let j = 0; j < 12; j++) {
            if (target[i][j]) {
                frame[array_pos] = (frame[array_pos] | (1 << shift_pos)) >>> 0;
            }
            array_pos = shift_pos === 0 ? array_pos + 1 : array_pos;
            shift_pos = shift_pos === 0 ? 31 : shift_pos - 1;
        }
    }

   let longArrayResult = "unsigned long frame[] = {\n";
   for (let i = 0; i < 3; i++) {
       longArrayResult += `    0x${frame[i].toString(16).toUpperCase()},\n`;
   }
   longArrayResult += "};";

   const finalResult = format2DArray(target) + "\n\n" + longArrayResult;
    setConversionResult(finalResult);
};


useEffect(() => {
  // Verifica se estamos no ambiente de navegador
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    // Cria o elemento de script
    const script = document.createElement('script');

    // Define o conteúdo do script
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-SE4NY559V3');
    `;

    // Insere o script no início do body
    document.body.insertBefore(script, document.body.firstChild);

    // Limpeza ao desmontar o componente
    return () => {
      document.body.removeChild(script);
    };
  }

}, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render


  return (
    <>
    <Layout />

    <div className='p-sm absolute right-10 top-32 w-128 h-auto rounded-xl bg-gradient-to-b from-white to-transparent bg-opacity-70'
      onMouseEnter={() => setIsHelpVisible(true)}
      onMouseLeave={() => setIsHelpVisible(false)}
    >
      <h2 className='text-orange-700'>{isHelpVisible ? 'Matriz de LEDs do Arduino UNO R4 WiFi' : 'Está com dúvidas?'}</h2>
      <p 
        className={`text-gray-900 text-justify transition-all ease-in duration-300 ${isHelpVisible ? 'h-144 opacity-100' : 'h-0 opacity-0'}`}
      >
       O resultado será uma matriz bi-dimensional e um array de 32 bits. Escolha o que desejar usar.
        Se tiver dúvidas de como usar o resultado, vá em "Busca rápida" e procure por <b className='text-gray-900'>uno r4 </b> 
        (tudo em minúsculo mesmo).
      </p>
    </div>

    <div className='top-52 absolute left-1/2 -translate-x-1/2 text-center'>
      <div className='p-sm'>
    <h1>Arduino's LED Matrix</h1>
    <h2 className=' rounded-lg '>Matriz de LEDs do Arduino</h2>
    </div>
    <div className='flex flex-row rounded-2xl ring-1 ring-orange-500 bg-black bg-opacity-70'>
    
      <div className='flex flex-row bg-transparent m-4 p-sm rounded-2xl'>
      <SelectableMatrix onConvert={bits2long}/>
      </div>
      <div>
      <pre className='text-md'>{conversionResult}</pre>
      </div>
    </div>
    </div>
    </>
  );
};

export default MatrixPage;

export const Head = ({ data }) => (
  <SEO
    title={"LED Matrix Editor"}
    description={"Editor para matriz de LEDs do Arduino"}
    pathname={"toolboxMatrix/"}
    children={""}
    pageType={"Article"}
    publishedAt={"2023-09-01"}
    thumbnail={"/manualdomaker-toolbox.png"}
  />
);
