import React, { useState } from 'react';

function SelectableMatrix(props) {
  // Função para criar a matriz inicial
  const initialMatrix = () => {
    const rows = [];
    for (let i = 0; i < 8; i++) {
        rows.push(Array(12).fill(0));
    }
    return rows;
  };

  const [matrix, setMatrix] = useState(initialMatrix);

  const toggleCell = (row, col) => {
    const newMatrix = [...matrix];
    newMatrix[row][col] = matrix[row][col] === 0 ? 1 : 0;
    setMatrix(newMatrix);
  };

  return (
    <div>
      {matrix.map((row, rowIndex) => (
        <div className="opacity-80 ml-8 overflow-hidden" key={rowIndex} style={{ display: 'flex' }}>
          {row.map((cell, colIndex) => (
            <div
              key={colIndex}
              onClick={() => toggleCell(rowIndex, colIndex)}
              style={{
                width: '20px',
                height: '20px',
                border: '2px solid black',
                borderRadius: '5px',
                backgroundColor: cell === 0 ? 'white' : '#F97316',
                cursor: 'pointer' 
              }}
            />
          ))}
        </div>
      ))}
      <button className='ml-8 mt-4 ring-1 ring-orange-500 rounded-md h-10 w-56' onClick={() => props.onConvert(matrix)}>Convert to Array</button>
    </div>
  );
}

export default SelectableMatrix;